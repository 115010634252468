import React from 'react'
import t from '../util/page-one-strings';
import AppStore from './reusable/app-store-container';

export default function PageFour() {
    return (
        <div className='flex flex-col md:flex-row justify-left items-left py-20 bg-green-500 lg:px-40' id='9'>

            <div className=' justify-left items-left py-10'>
                <h1 className='text-5xl font-medium px-0 md:px-0 md:w-1/2 text-left ml-10 md:ml-20'>Download <span className='text-white font-bold'>Mezopay App</span> for free from Apple & Play Store</h1>
                <AppStore flex={"flex-col md:ml-0 md:flex-row"} common={'w-40 mt-5 mr-5 w-4/5'}/>
            </div>
            <img src={t.barcode} alt='barcode' className='w-52 mt-5 ml-10 mt-10' />
        </div>

    )
}
