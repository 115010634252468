import './App.css';
import Footer from './components/footer';
import Header from './components/header';
import PageFive from './components/page-five';
import PageFour from './components/page-four';
import PageOne from './components/page-one';
import PageThree from './components/page-three';
import PageTwo from './components/page-two';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div>
      <Header />
      <PageOne/>
      <PageTwo/>
      <PageThree/>
      <PageFour/>
      <PageFive/>
      <Footer/>
    </div>
  );
}

export default App;
