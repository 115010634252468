
import Twitter from '../asset/twitter.svg';
import Facebook from '../asset/facebook.svg';
import Instagram from '../asset/instagram.svg';
import Linkedin from '../asset/linkedin.svg';
import Arrow from '../asset/arrow.svg';
import SS from '../asset/ss.svg';
import Rolling from '../asset/rolling.svg';
import Tees from '../asset/tees.svg';
import Jay from '../asset/jay.svg';
import George from '../asset/george.svg';
import Pearl from '../asset/pearl.svg';
import Wallet from '../asset/wallet.svg';
import P2P from '../asset/p2p.svg';
import SUN from '../asset/sun.svg';
import CART from '../asset/cart.svg';
import METLPHONE from '../asset/Frame.png';
import Line from '../asset/bent-line.svg';
import Google from '../asset/google-play.svg';
import Apple from '../asset/app-store.svg';
import Barcode from '../asset/barcode.svg';
import Email from '../asset/email.svg';
import CallUs from '../asset/callus.svg';
import Location from '../asset/location.svg';
import MezopayLogo from '../asset/mezopay-logo-white.svg';


const titles = {
    enjoy: "Enjoy a Better Payment Experience with Mezopay",
    mezopayOffers: "Mezopay offers a range of convenient payment services, including digital wallet payments, USSD transactions, point-of-sale payments, bank-to-bank transfers, peer-to-peer transfers, and bill payments for electricity and cable.",
    p1Icons: [
        {
            id: 1,
            icon: Twitter
        },
        {
            id: 2,
            icon: Facebook
        },
        {
            id: 3,
            icon: Instagram
        },
        {
            id: 4,
            icon: Linkedin
        },
        {
            id: 5,
            icon: Arrow
        },
    ],
    trusted: "Trusted by 100+ Companies",
    currentStatus: "We currently serve a variety of businesses and customers, who are satisfied with our services.",
    companiesIcon: [
        { icon: SS },
        { icon: Rolling },
        { icon: Tees },
        { icon: Jay },
        // { icon: George },
        { icon: Pearl }
    ],
    caroulSettings : {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5
      },
      'page2.left.item' :[
        {
            icon: Wallet,
            title:"Secure Wallet",
            body:"We offer a secure wallet for storing and easily accessing your money"
        },
        {
            icon: CART,
            title:"Purchase Metl Gas",
            body:"At Mezopay, we offer a wide range of cooking gas to suit your needs, including domestic cooking gas, commercial cooking gas, and auto gas."
        },
        {
            icon: P2P,
            title:"P2P Transfer Services",
            body:"Our bank-to-bank and peer-to-peer transfer services make it easy to transfer money to other accounts."
        },
        {
            icon: SUN,
            title:"Utility & Recharge Card",
            body:"Pay for your utility bills and purchase recharge card on the go."
        },
      ],
      'page22.left.item' :[
        {
            icon: Wallet,
            title:"Secure Wallet",
            body:"We offer a secure wallet for storing and easily accessing your money"
        },
        {
            icon: P2P,
            title:"P2P Transfer Services",
            body:"Our bank-to-bank and peer-to-peer transfer services make it easy to transfer money to other accounts."
        },
        {
            icon: SUN,
            title:"Utility & Recharge Card",
            body:"Pay for your utility bills and purchase recharge card on the go."
        },
      ],
      'page2.right.item' :[
        {
            title:`Swift  Bill Payment services`,
            body:"We also offer bill payment services for electricity and cable, allowing you to pay your bills easily and conveniently from your Mezopay account."
        },
        {
            icon: CART,
            title:"Purchase Metl Gas",
            body:"At Mezopay, we offer a wide range of cooking gas to suit your needs, including domestic cooking gas, commercial cooking gas, and auto gas."
        },
       
      ],
      'metl.phone':METLPHONE,
      accessPurpose:"Access our Multipurpose Ecosystem!",
      bentLine: Line,
      moreProductivity:'More productivity and less stress guaranteed ',
      download:"Download Mezopay App for free from Apple & Play Store",
      apple:Apple,
      google:Google,
      barcode:Barcode,
      leaveMessage: "Leave a message",
      fill:"Please fill in all required fields.",
      haveQuestion:"Have a question?",
      ourCustomer:"Our customer service is always available to reply all queries ",

      contactItem:[
        {
            icon:Email,
            title:"Email Us",
            body:"hello@mezopay.com"
        },
        {
            icon:CallUs,
            title:"Call Us",
            body:"+234 913 357 7245"
        },
        {
            icon:Location,
            title:"Location",
            body:"No 36 Turnbull, Ikoyi, Lagos."
        }
      ],
      mezopayLogo:MezopayLogo,
      c:'©2023 Mezopay a product of mezovest limited. All rights reserved',
      privacy:"Privacy Policy  Terms of Use"

}
export default titles;