import React, { useState } from 'react'
import MezopayLog from '../asset/mezopay-logo.svg';
import Button from './reusable/button';
import { Link } from 'react-scroll';

export default function Header() {
    const [active, setActive] = useState('1')

    const menuItems = [
        {
            title: "Home",
            id: '1'
        },
        {
            title: "Services",
            id: '2'
        },
        {
            title: "About Us",
            id: '7'
        },
        {
            title: "Contact",
            id: '4'
        }
    ]
    return (
        <header className='flex justify-between px-5 py-14 md:px-48' id='header'>
            <img src={MezopayLog} alt="logo" className='w-1/4 md:w-36' />
            <div className='flex justify-around hidden md:inline-flex'>
                {
                    menuItems.map(item => {
                        return <p key={item.id} className='font-bold'>
                            <Link
                                to={item.id}
                                spy={true}
                                smooth={true}
                                duration={500}
                                onClick={() => setActive(item.id)}
                                className='px-5 cursor-pointer'
                                style={{ color: active === item.id ? "#26D506" : null, color: active === item.id && '#26D506',
                                borderBottom: item.id === "5" ? "1px solid #F77F00" : null }}
                                >{item.title}</Link>
                        </p>
                    })
                }
            </div>
            <Button 
            id={'9'}
            onClick={() => setActive('9')}
            title={"Get Started"} 
            style={'bg-green-500 text-xs rounded-3xl font-bold text-white px-8 py-2 md:w-40'} />
        </header>
    )
}
