import React from 'react'
import { Link } from 'react-scroll';

export default function Button({ title, style, bprops, id ,onClick}) {
    return (
        <button
       
            className={style}>
            <Link
                smooth={true}
                duration={500}
                to={id}>
                {title}
            </Link>
        </button>
    )
}
