import React, { useState } from 'react'
import t from '../util/page-one-strings';
import Input from './reusable/input';
import Button from './reusable/button';
import List from './reusable/list';
import axios from 'axios';
import { toast } from 'react-toastify'
import ReCAPTCHA from "react-google-recaptcha";

export default function PageFive() {

  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [message, setMessage] = useState('')
  const recaptchaRef = React.createRef();


  const handleSendMail = (e) => {
    e.preventDefault()
    recaptchaRef.current.executeAsync(); // Execute reCAPTCHA

    let data = {
      email: email,
      name: name,
      message: message,
      source:"mezopay"

    }
    if (email.length === 0 || name.length === 0 || message.length === 0) {
      toast.error("All fields are required")

    } else {
      if (recaptchaRef.current) {
        recaptchaRef.current.executeAsync(); // Execute reCAPTCHA
     }      
       axios.post('https://api.community.mezovest.com/send-mail-2', data)
          .then(data => {
            console.log(data)
            toast.success("Thank you for reaching out. You will be contacted by one of our team members within 24hrs")
            setMessage('')
            setEmail('')
            setName('')
          }).catch(err => {
            toast.error("An error occurred, Please try again later")
            console.log(err)
          })
    }
  }

  const handleRecaptchaChange = (value) => {
    console.log('Captcha token: ', value)
  }
  return (
    <div className='md:flex my-20 lg:px-56' id='4'>
      <div className='hidden'>
        <h3 className='text-5xl'>Leave a message</h3>
        <p className='text-xl'>{t.fill}</p>
        <ReCAPTCHA
                ref={recaptchaRef}
          size="invisible"
          sitekey="6Lc7oFQmAAAAAO-8p8d64q5xG4chMNjTfu4P9X0p"
          onChange={handleRecaptchaChange} />
        <Input placeholder={"Name"} type={"text"} value={name} onChange={(e) => setName(e.target.value)} />
        <Input placeholder={"Email"} type={"text"} value={email} onChange={(e) => setEmail(e.target.value)} />
        <textarea required placeholder={'Message'} value={message} onChange={(e) => setMessage(e.target.value)} />
        <Button title={"Submit"} onClick={handleSendMail} />
      </div>
      <div className='flex flex-col w-full md:flex-row-reverse md:pr-10 px-5'>
        <div className='py-16 md:pl-20'>
          <h2 className='text-5xl font-bold'>Have a <br /><span className='text-green-500'>question?</span></h2>
          <p className='py-5 text-xl'>{t.ourCustomer}</p>
          {
            t.contactItem.map((item, index) => {
              return (
                <>{
                  index === 1 ? (<a href={`tel:${item.body}`} className="text-black-1000">
                    <div className="flex">
                      <img className="pr-5 text-black" src={item.icon} alt="screenshot" />
                      <div className="p-3">
                        <p className="font-bold">{item.title}</p>
                        <p>{item.body}</p>
                      </div>
                    </div>
                  </a>) : index === 0 ? (
                    <a href={`mailto:${item.body}`} className="text-black-1000">
                      <div className="flex">
                        <img className="pr-5 text-black" src={item.icon} alt="screenshot" />
                        <div className="p-3">
                          <p className="font-bold">{item.title}</p>
                          <p>{item.body}</p>
                        </div>
                      </div>
                    </a>) :
                    (<div className="flex">
                      <img className="pr-5" src={item.icon} alt="screenshot" />
                      <div className="p-3">
                        <p className="font-bold">{item.title}</p>
                        <p>{item.body}</p>
                      </div>
                    </div>)
                }
                </>
              )
              // return (
              //     <div key={index} className='flex py-5'>
              //         {item.icon && <img src={item.icon} alt='icon' className='w-16' />}
              //         <div className='ml-5'>
              //             <h1 className='font-bold text-xl'>{item.title}</h1>
              //             <p className='text-xl'>{item.body}</p>
              //         </div>
              //     </div>
              // )
            })
          }
        </div>
        <div className='px-5 mx-0 md:mx-0 md:w-1/2 rounded-3xl overflow-hidden shadow-lg bg-white py-10' id="mbox">
          <h3 className='font-bold text-5xl md:hidden'>Leave a <br /><span className='text-green-500'>message</span></h3>
          <h3 className='font-bold text-5xl hidden md:block'>Leave a <span className='text-green-500'>message</span></h3>
          <p className='py-3 text-xl'>{t.fill}</p>
          <ReCAPTCHA
                  ref={recaptchaRef}

            size="invisible"
            sitekey="6Lc7oFQmAAAAAO-8p8d64q5xG4chMNjTfu4P9X0p"
            onChange={handleRecaptchaChange} />
          <Input placeholder={"Name"} type={"text"} value={name} onChange={(e) => setName(e.target.value)} />
          <Input placeholder={"Email"} type={"text"} value={email} onChange={(e) => setEmail(e.target.value)} />
          <textarea
            required
            value={message} onChange={(e) => setMessage(e.target.value)}
            placeholder={'Message'}
            className='rounded-2xl 
                    px-5 py-3 border
                    border-grey-500 my-2 w-full' style={{ minHeight: '150px' }} />
          <button
            onClick={handleSendMail}
            title={"Submit"}
            className={'bg-green-500 rounded-3xl font-bold text-white px-8 py-2'}>Submit</button>
        </div>
      </div>

    </div>
  )
}
