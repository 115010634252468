import React from 'react'
import t from '../util/page-one-strings';
import mezopay from '../asset/mezzopay.svg';
import metlgas from '../asset/Metlgas.svg';
import shop from '../asset/shop.svg';
import phone from '../asset/phone_mockup.svg';
import mezo from '../asset/mezoecosystem.png';

export default function PageThree() {
    return (
        <div id='7' className='bg-cover md:py-10 flex flex-col justify-center md:items-center p-5'>
            <p className='font-bold text-4xl'>Access our Multipurpose Ecosystem!</p>
            <img src={t.bentLine} alt="line" className='w-1/2 md:w-60 md:-mt-2 -mt-12 ml-5' />
            <p className='pr-20 py-10 md:py-0 mt-5'>{t.moreProductivity}</p>

            <div className='flex flex-col justify-center align-center w-full'>
                {/* <div className='flex justify-between md:justify-center'>
                    <img src={metlgas} alt='metlgas' className='shadow-2xl md:w-1/6  md:mx-5' />
                    <img src={mezopay} alt='mezopay' className='shadow-2xl md:w-1/6  md:mx-5' />
                    <img src={shop} alt='shop' className='shadow-2xl md:w-1/6 md:mx-5' />
                </div>
                <img src={phone} alt='phone' className='md:w-2/6 align-center justify-center md:ml-auto md:mr-auto' /> */}
                <img src={mezo} alt='phone' className='md:w-2/4 align-center justify-center md:ml-auto md:mr-auto' />

            </div>
        </div>
    )
}
