import React from 'react'
import t from '../util/page-one-strings';

export default function Footer() {
  return (
    <div className='flex px-5 lg:px-56 pb-5 flex-row bg-black text-white text-center justify-between md:py-10'>
        <img src={t.mezopayLogo} alt='mezopay-logo' className='w-16 md:w-32 mr-5' />
      <p className='text-xs text-left pt-3 md:pt-0 md:text-lg'>{t.c}</p>
         <a href='https://www.mezovest.com/privacy-policy'><p className='hidden md:block md:text-lg'>{t.privacy}</p></a>
    </div>
  )
}