import React from 'react'
import t from '../util/page-one-strings';
import MezoPhone from '../asset/Frame.png';
import Arrow from '../asset/arrow.svg';
// import HalfMezoPhone from '../asset/half-phonee.svg';
import HalfMezoPhone from '../asset/Phone_Mockup_Half.svg';
import Twitter from '../asset/twitter.svg';
import Facebook from '../asset/facebook.svg';
import Instagram from '../asset/instagram.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function PageOne() {

    const settings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2
    };

    return (
        <div id='1' className='lg:px-14'>
            <div className='flex justify-between overflow-x-hidden py-5 md:py-10 md:py-10'>
                <div className='px-2 md:px-20 md:w-1/2 text-align:left' id="page1">
                    <h1 className='font-bold text-4xl px-4 w-40 md:w-full md:hidden'>Enjoy a Better <span className='text-green-500'>Payment Experience</span> with <span className='text-green-500'>Mezopay</span></h1>
                    <div className='px-10 md:pt-20'>
                        <h1 className='font-bold text-5xl px-4 py-1 hidden md:block'>Enjoy a Better </h1>
                        <h1 className='font-bold text-5xl px-4 py-1 hidden md:block'><span className='text-green-500'>Payment Experience</span></h1>
                        <h1 className='font-bold text-5xl px-4 py-1 hidden md:block'>with <span className='text-green-500'>Mezopay</span></h1>
                    </div>
                    <p className='py-5 px-4 pr-40 md:pr-0 md:pl-16'>{t.mezopayOffers}</p>
                    <div className='flex md:pl-16 pl-5'>
                        <a href='https://play.google.com/store/apps/details?id=com.mezovest.mezopay' target="_blank">
                            <img src={t.google}
                                alt='google'
                                className='w-28' />
                        </a>
                        <a href='https://apps.apple.com/us/app/mezopay/id1569381406' target='_blank'>
                            <img src={t.apple}
                                alt='apple'
                                className='w-28 ml-5'
                            />
                        </a>
                    </div>
                    <div className="flex pt-10 pb-10 pl-5 mx-12 hidden: md:flex">
                        <a href="https://twitter.com/mezopayapp" target="_blank"><img className="mr-10" src={Twitter} alt="twitter" /></a>
                        <a href="https://facebook.com/mezopayapp " target="_blank"><img className="mr-10" src={Facebook} alt="facebook" /></a>
                        <a href="https://instagram.com/mezopayapp" target="_blank"><img className="mr-10" src={Instagram} alt="instagram" /></a>
                        {/* <img className="mr-10" src={Linkedin} alt="linkedin" /> */}
                        <img className="mr-10" src={Arrow} alt="arrow" />
                    </div>
                </div>
                <img src={MezoPhone} alt='mezopay' className='hidden md:block mr-24 lg:w-1/3 lg:mt-10 md:w-1/2 h-1/2 md:mt-28' />
                <img src={HalfMezoPhone} alt='mezopay' className='-mt-64 -ml-40 md:hidden' />

            </div>
            <div>
                <h1 className='text-left md:text-center text-4xl font-bold md:py-5 px-5 pt-0'>Trusted by <span className='text-green-500'>100+</span> Companies</h1>
                <p className='text-center py-5'>{t.currentStatus}</p>
            </div>

            <div className='md:hidden mb-20'>
                <Slider  {...settings2} className='mx-10'>
                    {t.companiesIcon.map(item => (
                        <div key={item.id}>
                            <img src={item.icon} alt='company' />
                        </div>
                    ))}
                </Slider>
            </div>
            <div className='hidden md:block px-48 pb-20'>
                <Slider  {...t.caroulSettings} className='mx-10'>
                    {t.companiesIcon.map(item => (
                        <div key={item.id}>
                            <img src={item.icon} alt='company' />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}
