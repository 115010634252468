import React from 'react'
import t from '../util/page-one-strings';
import List from './reusable/list';

export default function PageTwo() {
    return (
        <div className='px-5 bg-deep-green text-white py-10 lg:px-20' id='2'>
            <div className='md:hidden'>
                <h1 className='font-bold text-4xl py-5'>Swift
                    <br /><span className='text-green-500'>Bill Payment</span>
                    <br />
                    services</h1>
                <p className='pb-10'>We also offer bill payment services for electricity and cable, allowing you to pay your bills easily and conveniently from your Mezopay account.</p>
            </div>
            <div className='md:flex justify-between md:hidden'>
                <div>
                    {
                        t['page2.left.item'].map((item, index) => {
                            return (
                                <div key={index} className='py-5 md:flex-column md:flex-end md:text-right md:w-96'>
                                    {item.icon && <img src={item.icon} alt='icon' className='md:inline' />}
                                    <h1 className='font-bold text-2xl text-green-500 py-2'>{item.title}</h1>
                                    <p>{item.body}</p>
                                </div>
                            )
                        })
                    }
                </div>
                <img src={t['metl.phone']} alt="metl-phone" className='hidden md:block' />
                <div className='hidden md:block lg:w-96'>
                    <h1 className='font-bold text-4xl py-5'>Swift
                        <br /><span className='text-green-500'>Bill Payment</span>
                        <br />
                        services</h1>
                    <p>We also offer bill payment services for electricity and cable, allowing you to pay your bills easily and conveniently from your Mezopay account.</p>
                    <div className='py-5 md:w-1/2'>
                        <img src={t['page2.right.item'][1].icon} alt='icon' />
                        <h1 className='font-bold text-2xl text-green-500 py-2'>{t['page2.right.item'][1].title}</h1>
                        <p>{t['page2.right.item'][1].body}</p>
                    </div>
                </div>
            </div>
            <div className='md:flex justify-around hidden'>
                <div>
                    {
                        t['page22.left.item'].map((item, index) => {
                            return (
                                <div key={index} id="case1" className='py-5 md:flex-column md:flex-end md:text-right md:w-96'>
                                    {item.icon && <img src={item.icon} alt='icon' className='md:inline' />}
                                    <h1 className='font-bold text-2xl text-green-500 py-2'>{item.title}</h1>
                                    <p>{item.body}</p>
                                </div>
                            )
                        })
                    }
                </div>
                <img src={t['metl.phone']} alt="metl-phone" className='hidden md:block lg:w-1/3 lg:mt-10 md:w-1/2 h-1/2 md:mt-28 px-5' />
                <div className='hidden md:block lg:w-96'>
                    <h1 className='font-bold text-4xl py-5'>Swift
                        <br /><span className='text-green-500'>Bill Payment</span>
                        <br />
                        services</h1>
                    <p>We also offer bill payment services for electricity and cable, allowing you to pay your bills easily and conveniently from your Mezopay account.</p>
                    <div className='py-5 md:w-1/2'>
                        <img src={t['page2.right.item'][1].icon} alt='icon' />
                        <h1 className='font-bold text-2xl text-green-500 py-2'>{t['page2.right.item'][1].title}</h1>
                        <p>{t['page2.right.item'][1].body}</p>
                    </div>
                </div>
            </div>
            <div className='hidden'>
                {
                    t['page2.right.item'].map((item, index) => {
                        return (
                            <div key={index}>
                                {item.icon && <img src={item.icon} alt='icon' />}
                                <h1 className='font-bold'>{item.title}</h1>
                                <p>{item.body}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
