import React from 'react'
import t from '../../util/page-one-strings';

export default function AppStore({ flex, common, ml, style }) {
    return (
            <div className={`${flex} flex pl-20`}>
                <a href='https://play.google.com/store/apps/details?id=com.mezovest.mezopay' target="_blank" style={{ display: 'block', marginRight: '10px' }}>
                    <img src={t.google} alt='google' className={`${common}`} style={{ height: 'auto' }} />
                </a>
                <a href='https://apps.apple.com/us/app/mezopay/id1569381406' target='_blank' style={{ display: 'block' }}>
                    <img src={t.apple} alt='apple' className={`${common} ${ml}`} style={{ height: 'auto' }} />
                </a>
            </div>

    )
}
